.react-calendar-timeline .rct-vertical-lines .rct-vl {
    z-index: 19 !important;
}

.md-list--menu {
    min-width: 150px !important;
}

.md-selection-control-container {
    width: 25% !important;
    display: inline !important;
}

.tabs-reports .md-tab--active {
    border-bottom: 2px solid white;
}