
.group-title {
    line-height: 1;
    height: 100%;
    padding: 10px;
}

.group-title .title-sub {
    white-space: pre-line;
}

.capacidad {
    color: #777;
    margin-top: 5px;
    display: flex;
}

.icon-label {
    font-size: 16px;
    padding: -5px;
    color: #ffc107; 
}

.tooltip-content-group {
    width: 200px;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    display: flex;
}
